<template>
  <div id="app">
    <agile  :initial-slide="3" :options="myOptions">
      <img v-for="slide in slides" :key="slide.id" class="slide" v-lazy="slide.gallery" alt="">
      <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
      <template slot="nextButton"><i class="fab fa-chevron-right"></i></template>
    </agile>
  </div>
</template>

<script>
import villaDetails from '../data/villaDetails.json'
import {VueAgile} from 'vue-agile'

export default {
  components: {
    agile: VueAgile,
  },
  data() {
    return {
      slides: villaDetails,


    }
  }
}
</script>