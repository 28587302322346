<template>
  <div class="house-details">
    <div class="house-detils-semi" v-for="detail in villaDetails" :key="detail.id">

      <div class="house-details-inner" v-if="proId === detail.id">

        <div class="villas-list">
          <div class="villas-list-inner">
            <div class="villas-list-image">

              <img :src="detail.coverImage" alt="" class="villas-image">
              <div class="villas-list-overlay">
                <button class="villas-gallery-button" @click="scrollToGallery({behavior:'smooth'})">Check gallery
                </button>
              </div>
            </div>
            <div class="villas-list-text">
              <div class="villas-list-text-left">
                <h1 class="villas-list-title">{{ detail.title }}</h1>
                <span class="villas-text-line"></span>
                <div class="location" v-for="location in detail.villaLocation" :key="location.id">
                  <img class="location-pin" :src="location.image" alt="">
                  <button class="villas-location-button" @click="scrollToLocation({behavior:'smooth'})">
                    {{ location.title }}
                  </button>
                </div>
                <div class="villas-list-strafta">
                  <div class="villas-icons" v-for="icon in detail.icons" :key="icon.id">
                    <h2>{{ icon.title }}</h2>
                    <img :src="iconImage(icon.icon)" alt="">
                  </div>
                </div>
                <p class="villas-decritption" v-html="detail.subtitle"></p>
                <p v-html="detail.description"></p>
              </div>
              <div class="villas-calendar">
                <div class="villas-buttons">
                  <h2>Availability</h2>
                  <p v-html="detail.price"></p>
                  <HotelDatePicker :gridStyle="false" :showSingleMonth="true" :minNights="7" :alwaysVisible="true"
                                   :showPrice="true" priceSymbol='€' :periodDates="detail.periodDates"
                                   :disabledDates="detail.disabledDates" @check-in-changed="getStartDate"
                                   @check-out-changed="getEndDate" :bookings="detail.bookings">
                  </HotelDatePicker>
                  <div class="legend">
                    <div class="legend-inner" id="available">Available</div>
                    <div class="legend-inner" id="booked">Booked</div>
                    <div class="legend-inner" id="selected">Selected</div>
                  </div>
                  <button class="calendar-buttons" id="button-one"><a href="tel:+385976750979">Call <b>+385 97 675
                    0979</b></a></button>
                  <button class="calendar-buttons" id="button-two" @click="show()">Enquiry</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="villas-services">
          <div class="villas-services-inner">
            <h2>Details</h2>
            <div class="villas-services-inner-list">
              <div class="details-section1" v-for="subItem in detail.details" :key="subItem.id">
                <h4>{{ subItem.detailsTitle }}</h4>
                <ul>
                  <li>{{ subItem.detailsSubtitle }}</li>
                </ul>
              </div>

            </div>
          </div>
        </div>
        <div class="villas-amenities">
          <div class="villas-amenities-inner">
            <h2>Amenities</h2>
            <div class="villas-amenities-inner-list">
              <div class="villas-amenities-inner-list-text">
                <div class="amenities-section" v-for="subAmenities in detail.amenities" :key="subAmenities.id">
                  <h4>{{ subAmenities.amenitiesTitle }}</h4>
                  <ul>
                    <li v-for="subTitle in subAmenities.amenitiesSubtitle" :key="subTitle.id">{{ subTitle }}</li>
                  </ul>
                </div>
              </div>
              <div class="villas-amenities-inner-list-image">
                <img v-lazy="detail.image1" alt="Hvar Villas">
                <img v-lazy="detail.image2" alt="Villas in Hvar">
              </div>
            </div>

          </div>
        </div>

        <div class="villas-stuffing">
          <div class="villas-stuffing-inner">
            <h2>Service and staffing</h2>
            <div class="villas-stuffing-inner-list">
              <div class="villas-stuffing-inner-list-text">
                <div class="stuffing-section" v-for="subService in detail.service" :key="subService.id">
                  <h4>{{ subService.serviceTitle }}</h4>
                  <ul>
                    <li v-for="subTitle in subService.serviceSubtitle" :key="subTitle.id">{{ subTitle }}</li>
                  </ul>
                </div>
              </div>
              <div class="villas-stuffing-inner-list-image">
                <img v-lazy="detail.image3" alt="Croatia Hvar villa with pool">
              </div>
            </div>
            <p></p>
          </div>
        </div>
        <div class="carousel-container">
          <h2>Gallery</h2>
          <agile :initial-slide="4" :options="myOptions">
            <img v-for="photo in detail.gallery" :key="photo.id" class="slide" :src="photo.image" alt="Villas in Hvar Town with pool">
            <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
            <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
          </agile>
        </div>
        <div class="villas-important-info">
          <div class="villas-important-info-inner">
            <h2>Important Information</h2>
            <div class="villas-important-info-inner-list">
              <div class="important-info-section" v-for="villaInfo in detail.importantInfo" :key="villaInfo.id">
                <ul>
                  <li v-for="importantInfo in villaInfo.houseInfo" :key="importantInfo.id" v-html="importantInfo"></li>
                </ul>
              </div>
            </div>

          </div>
        </div>
        <div class="villas-location">
          <div class="villas-location-inner">
            <h2>Location</h2>
            <div class="villas-location-inner-list">
              <div class="location-section">
                <LMap :zoom="detail.zoom" :center="detail.location" :maxZoom="15">
                  <LTileLayer :url="url"></LTileLayer>
                  <LMarker :lat-lng="detail.location"></LMarker>
                </LMap>
              </div>
            </div>
            <p></p>
          </div>
        </div>


      </div>
    </div>
  </div>

</template>

<script>
import HotelDatePicker from 'vue-hotel-datepicker'
import 'vue-hotel-datepicker/dist/vueHotelDatepicker.css';
import houseDetails from '../data/villaDetails.json'
import {LMap, LMarker, LTileLayer} from 'vue2-leaflet';
import {VueAgile} from "vue-agile";
import Modal from "@/components/Modal";
import store from '../store'
import moment from 'moment'


export default {


  data() {
    const SEOdata = store.getters.getVillaDetails(this.$route.params.id);
    return {
      proId: this.$route.params.id,
      seoTitle: SEOdata.title,
      markups: SEOdata.markup,
      villaDetails: houseDetails,
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 16,
      center: [43.1729, 16.4411],
      bounds: null,
      props: [houseDetails],
      startDate: '',
      endDate: '',


      myOptions: {
        navButtons: false,

        responsive: [
          {
            breakpoint: 350,
            settings: {
              dots: false,
              navButtons: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              navButtons: true
            }
          },

          {
            breakpoint: 900,
            settings: {
              navButtons: true,
              dots: true,
              infinite: false

            }
          }
        ]
      }
    }

  },
  metaInfo() {
    return {
      title: `${this.seoTitle}  One Of The Best Villas In Hvar Town`,
      meta: [
        {name: "robots", content: "index,follow"},
        {
          name: 'description',
          content: `Check out and book amazing ${this.seoTitle},  in Hvar town, get 24/7 concierge service and have a vacation of your dreams`
        }
      ],
      script: [{
        type: 'application/ld+json',
        json: this.markups
      }]
    }

  },
  components: {
    HotelDatePicker,
    LMap,
    LTileLayer,
    LMarker,
    agile: VueAgile
  },
  methods: {
    scrollToGallery(options) {
      const el = this.$el.getElementsByClassName('carousel-container')[0];

      if (el) {
        el.scrollIntoView(options);
      }
    },
    scrollToLocation(options) {
      const el = this.$el.getElementsByClassName('villas-location')[0];

      if (el) {
        el.scrollIntoView(options);
      }
    },


    format_date(value) {
      if (value) {
        return moment(String(value), "DD/MM/YY")
      }
    },

    getStartDate(newDate) {
      this.startDate = newDate

    },

    getEndDate(newDate) {
      this.endDate = newDate
    },

    show() {
      this.$modal.show(
          Modal,
          {title: this.startDate && this.endDate ? `${this.startDate.getDate()}.${this.startDate.getMonth()}.${this.startDate.getFullYear()} - ${this.endDate.getDate()}.${this.endDate.getMonth()}.${this.endDate.getFullYear()} for ${this.seoTitle}` : "Please select the date range"},
        
      );
    },

    iconImage: function (path) {
      return require("@/" + path);
    }
  }
}


</script>