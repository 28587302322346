<template>
  <div class="villas">
    <div class="town-villas-landing">
      <img src="../assets/cover.jpeg" alt="Hvar villas rental">
      <div class="town-villas-landing-overlay">
        <div class="town-villas-landing-inner">
          <h1>Hvar Villas Rental</h1>
        </div>
      </div>
    </div>
    <div class="town-villas-list" v-for="detail in details" :key="detail.id">
      <div class="town-villas-list-inner">
        <div class="town-villas-list-image">
          <img v-lazy="detail.coverImage" alt="Hvar villas" class="villas-image">
        </div>


        <div class="town-villas-list-text">
          <h2 class="town-villas-list-title">{{detail.title}}</h2>
          <span class="town-villas-text-line"></span>
          <div class="town-villas-list-strafta">
            <div class="villas-icons" v-for="icon in detail.icons" :key="icon.id">
              <h2>{{ icon.title }}</h2>
              <img v-lazy="iconImage(icon.icon)" alt="Hvar villas">
            </div>
          </div>
          <p class="town-villas-decritption" v-html="detail.subtitle"></p>
          <div class="town-villas-buttons" >
            <router-link :to="{name: 'details', params:{id: detail.id, title: detail.title, markup:detail.markup}}">
              <button class="town-villas-button town-villas-readmore">Read more</button>
            </router-link>
            <button class="town-villas-button town-villas-enquiry" @click="show()">Send enquiry</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import houseDetails from '../data/villaDetails.json'
import Modal from "@/components/Modal";


export default {
  data() {
    
  
    return {
      modalTitle: houseDetails.id,
      details: houseDetails,
      loaded: false,
      
    }
  },

  metaInfo(){
    return{
      title:'Check Out The Best Villas In Hvar Town | Hvar Villas Rental',

    meta:[
      { name: "robots", content: "index,follow" },
      {
        name:"description", content:"Check our great Hvar villas rental collection and book one of these unique and amazing Hvar villas with pool"
      }

    ],
    link:[
      { rel: 'favicon', href: 'favicon.ico' }
    ]
    }
  },
  
  methods:{
    show() {
      this.$modal.show(
          Modal,
         console.log(...this.details)
      );
    },

    iconImage: function (path){
      return require("@/" + path);
    }
  }
}
</script>