<template>
  <div class="about">
    <div class="about-us">
      <img
          src="../assets/saki.jpeg"
          alt="">
      <div class="colored-line"></div>
      <div class="about-us-overlay">
        <div class="about-us-inner">
          <h1>About Us</h1>
          <p id="line"></p>
          <h3>We are here for you</h3>
          <p id="about-us-upper">Greetings to all!
            My name is Sanja and I am CEO of the Hvar house.
            I will be your host and concierge during your stay in Hvar.
            Here is one interesting story...
            I used to be a tourist in Hvar a long time ago ...and I came once and stayed forever. Namely, my first arrival in 2008. was marked by love at first sight.
            As a bachelor of law, I had the idea of an attorney's office, but it seems that life had a little better plan for me.

          </p>
          <p id="about-us-lower">
            Now, I am happily married, we have two beautiful daughters and I work in tourism.
            Love for Hvar was also at first sight.
            I got to know every part of this beautiful sunny town, found myself completely in tourism and a new, island life.
            I am ready to share these beauties with you.
            Maybe you want stay like me "for the rest of your life" but you will surely come at least one more time.

          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  metaInfo(){
    return{
    title:'About | Villa Rental | Hvar House Company',
    meta:[
      { name: "robots", content: "index,follow" },
      {
        name:'description', content:'Book luxury villa or villa with pool in Hvar old town directly from Host, and get free Speed boat transfer from mainland.'
      }

    ],
    link:[
      { rel: 'favicon', href: 'favicon.ico' }
    ]
    }
  },
}
</script>