<template>
  <div>
    <div v-for="slide in slider" :key="slide.id">

    <agile class="main" ref="main" :options="options1" :as-nav-for="asNavFor1">
      <div class="slide" v-for="(photo, index) in slide.gallery" :key="index" :class="`slide--${index}`"><img :src="photo"/></div>
    </agile>
    <agile class="thumbnails" ref="thumbnails" :options="options2" :as-nav-for="asNavFor2">
      <div class="slide slide--thumbniail" v-for="(photo, index) in slide.gallery" :key="index" :class="`slide--${index}`" @click="$refs.thumbnails.goTo(index)"><img :src="photo"/></div>
      <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
      <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
    </agile>
    </div>
  </div>
</template>
<script>
import { VueAgile } from 'vue-agile'
import villaDetails from '../data/villaDetails.json'
export default{
  components: {
    agile: VueAgile
  },
  data(){
    return{
      asNavFor1: [],
      asNavFor2: [],
      options1: {
        dots: false,
        fade: true,
        navButtons:false,

      },
      slider:villaDetails,

      options2: {
        autoplay: false,
        centerMode: true,
        dots: false,
        navButtons: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5
            }
          },

          {
            breakpoint: 1000,
            settings: {
              navButtons: true
            }
          }
        ]

      },
      slides:[]
    }

  },
  mounted () {
    this.asNavFor1.push(this.$refs.thumbnails)
    this.asNavFor2.push(this.$refs.main)
  }
}
</script>
