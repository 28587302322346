import Vuex from 'vuex'
import houseDetails from "../src/data/villaDetails.json"
import services from "../src/data/services.json"


const store = new Vuex.Store({
    state:{
        villaDetails: houseDetails,
        serviceDetails:services,
       
    },
    getters: {
        getVillaDetails: state => (id) => {
            return state.villaDetails.find(detail => detail.id === id) || { title: 'Not found', content: '......' }
        },
        getVillaTitle: state =>(title) =>{
                return state.villaDetails.find(villaTitle => villaTitle.title === title)
        },
        getServiceDetails: state => (id) => {
            return state.serviceDetails.find(detail => detail.id === id) || { title: 'Not found', content: '......' }
        },
       


    }

})

export default store